import {
  Button,
  LoadingSpinner,
  MenuListSelect,
} from '@rabbit/elements/shared-components';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from 'raviger';
import { SearchVendablesBySkuCode } from '@rabbit/search/cherchons';
import { DTVendable } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { useAppInfo } from '@rabbit/olive/utils/helpers.ts';
import ROUTE_NAME from '@rabbit/olive/utils/url-constants.tsx';
import { UserContext } from '@rabbit/olive/context/UserContext.tsx';
import { BL_Tech } from '@rabbit/bizproc/core';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { AVAILABLE_LANGUAGE_CODES } from '@rabbit/sage/utils/consts.ts';
import MenuItem from '@mui/material/MenuItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
};

export default function RegisterView() {
  const navigate = useNavigate();
  const { consumerPersonaData } = useContext(UserContext) || {};
  const params = useQueryParams()[0];
  const sku = params?.sku;
  const sn = params?.sn ?? false;
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [vendableData, setVendableData] = useState<DTVendable | null>(null);
  const UPC_PREFIX = t('CFG_COBRAND_UPC_PREFIX', { defaultValue: '' });
  const [lang, setLang] = useState<string>(
    window.localStorage.getItem('lang') ?? 'en'
  );

  const cobrand = t('CFG_COBRAND_LOGO');
  useEffect(() => {
    if (sku) {
      (async () => {
        const res = await SearchVendablesBySkuCode(`${UPC_PREFIX}${sku}`);
        if (res) setVendableData(res?.items[0]);
      })()
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [sku]);
  const productPlaceHolder = t(
    'CFG_COBRAND_LANDING_IMAGE_PRODUCT_PLACEHOLDER',
    { defaultValue: '' }
  );
  const defaultImage =
    t('CFG_COBRAND_LANDING_IMAGE_PRODUCT_PLACEHOLDER') || productPlaceHolder;
  const onClickRegisterProduct = () => {
    if (!vendableData) {
      navigate(
        consumerPersonaData ? ROUTE_NAME.PRODUCTS_SEARCH : ROUTE_NAME.AUTH
      );
      return;
    }

    if (sn) {
      window.localStorage.setItem('LRP_VendableSn', sn);
    }

    const path = consumerPersonaData
      ? `${ROUTE_NAME.PRODUCTS_PREVIEW}/${vendableData.docid}/register`
      : ROUTE_NAME.AUTH;

    if (!consumerPersonaData) {
      window.localStorage.setItem('LRP_VendableId', vendableData.docid);
      window.localStorage.setItem('LRP_VendableName', vendableData.title);
    }

    navigate(path);
  };
  const onClickSageProduct = () => {
    const host = `${window.location.protocol}//${window.location.host}`;
    const url = BL_Tech.getSageUrlFromOlive(host);
    const queryParams = new URLSearchParams();

    if (sku) queryParams.append('sku', sku);
    if (sn) queryParams.append('sn', sn);

    queryParams.append('lang', lang);
    window.location.replace(`${url}/login?${queryParams.toString()}`);
  };
  const onClickSageProductWithoutLogin = () => {
    const host = `${window.location.protocol}//${window.location.host}`;
    const url = BL_Tech.getSageUrlFromOlive(host);
    const queryParams = new URLSearchParams();

    if (sku) queryParams.append('sku', sku);
    if (sn) queryParams.append('sn', sn);

    queryParams.append('lang', lang);
    window.location.replace(
      `${url}/register-product?${queryParams.toString()}`
    );
  };

  const handleChange = async (event: SelectChangeEvent) => {
    setLang(event.target.value as string);
    window.localStorage.setItem('lang', event.target.value as string);
    await i18n.changeLanguage(event.target.value as string);
  };
  const availableLanguages = AVAILABLE_LANGUAGE_CODES.map((data) => ({
    value: data.code,
    label: data.language,
  }));

  return (
    <div className="bg-canvas flex grow flex-col lg:content-center">
      <div
        style={{}}
        className={`relative mt-1 w-full md:m-auto md:grid md:w-[480px] md:grid-cols-1 md:gap-12`}
      >
        <div
          className={`relative rounded-[6px] bg-white px-8 py-12 md:px-16 md:shadow-[4px_6px_24px_0px_rgba(0,0,0,0.08)]`}
        >
          {isLoading && <LoadingSpinner size="sm" overlay={true} />}
          {!isLoading && (
            <div className="flex flex-col">
              <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <div style={{ position: 'absolute', right: 10, top: 10 }}>
                  <MenuListSelect
                    label="lang"
                    value={lang}
                    handleChange={handleChange}
                    menuItem={availableLanguages}
                  />
                </div>
              </div>
              <img
                alt=""
                src={cobrand}
                className="mb-2.5 max-h-[72px] w-full object-contain"
              />
              <div className={`flex flex-col ${vendableData ? 'gap-5' : ''}`}>
                <div>
                  <div
                    className={`flex items-center ${
                      vendableData ? 'h-[360px]' : 'h-[400px]'
                    } w-full`}
                  >
                    <img
                      alt=""
                      src={
                        vendableData
                          ? vendableData.img && vendableData.img.length > 0
                            ? vendableData.img[0]
                            : defaultImage
                          : defaultImage
                      }
                      className="h-full w-full object-contain"
                    />
                  </div>
                  {vendableData && (
                    <div className="font-nunito text-center text-xl font-medium">
                      {vendableData.title}
                    </div>
                  )}
                </div>
                <div className="flex flex-col items-center">
                  <Button
                    onClick={onClickSageProduct}
                    className="mr-4 mb-3 w-fit pl-5 pr-5 "
                    kind="primary"
                    size="sm"
                  >
                    {t('message.registerProductAsInstaller')}
                  </Button>
                  <Button
                    size="sm"
                    onClick={onClickRegisterProduct}
                    className="mr-4 mb-4 w-fit"
                    kind="outline"
                  >
                    {t('message.registerProductAsConsumer')}
                  </Button>
                  <div
                    onClick={onClickSageProductWithoutLogin}
                    className="text-primary-900 mr-4 w-fit cursor-pointer text-sm font-bold"
                  >
                    {t('message.registerProductWithoutLogin')}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
